mobileMenuHandler = () => {
  const menuToggle = document.querySelector('.mobile-menu .menu-toggle, .mobile-menu .menu-header');
  const toggleMenu = document.querySelector('.mobile-menu .menu');

  menuToggle.addEventListener('click', (event) => {
    if (toggleMenu.style.display === 'none' || toggleMenu.style.display === '') {
      toggleMenu.style.display = 'block';
    } else {
      toggleMenu.style.display = 'none';
    }
  });
};

document.addEventListener('turbolinks:load', mobileMenuHandler);
